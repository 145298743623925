import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import { PasswordInput } from '../../../components/shared/form-control/PasswordInput';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import PasswordValidator from '../common/PasswordValidator';
import { isJWTValid } from '../../../utils/JwtUtils';
import ResetPasswordExpired from './ResetPasswordExpired';
import AuthService from '../../../services/AuthService';
import BaseService from '../../../services/BaseService';

const ResetPassword: FC = () => {
  const [password, setPassword] = useState('');
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const token = useMemo(() => query.get('token'), [query]);
  const tokenValid = useMemo(() => isJWTValid(token), [token]);

  const { t } = useTranslation('auth');
  const toasts = useToasts();
  const navigate = useNavigate();

  const doResetPassword = useCallback(() => {
    if (!tokenValid) {
      return;
    }

    setLoading(true);
    BaseService.recreateHttpClient(token);
    AuthService.resetPassword(password)
      .then((res) => {
        if (!res.data) {
          // Go into catch to display error
          throw new Error();
        }

        toasts.addToast({
          title: t('reset-password.toasts.success.title'),
          description: t('reset-password.toasts.success.description'),
          type: ToastType.INFO,
          expiresInMs: 10000,
        });
        navigate('/auth/login');
      })
      .catch(() => {
        toasts.addToast({
          title: t('reset-password.toasts.failure.title'),
          description: t('reset-password.toasts.failure.description'),
          type: ToastType.INFO,
          expiresInMs: 10000,
        });
      })
      .finally(() => {
        setLoading(false);
        BaseService.clearToken();
      });
  }, [navigate, password, t, toasts, token, tokenValid]);

  const detectSubmit = useCallback(
    (e: KeyboardEvent) => {
      if (valid && e.key === 'Enter') {
        doResetPassword();
      }
    },
    [doResetPassword, valid],
  );

  return !tokenValid ? (
    <ResetPasswordExpired />
  ) : (
    <>
      <Heading size={HeadingSize.H1}>{t('reset-password.heading')}</Heading>
      <div>{t('reset-password.subheading')}</div>
      <div className="w-96">
        <PasswordValidator password={password} isValid={setValid} />

        <PasswordInput
          name="password"
          id="password"
          autoFocus
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t('reset-password.inputs.password')}
          label={t('reset-password.inputs.password')}
          onKeyPress={detectSubmit}
          data-cy="reset-password-password"
        />
        <div className="px-10 text-center">
          <Button
            className="mt-6 w-full"
            type={ButtonType.PRIMARY}
            onClick={doResetPassword}
            disabled={loading || !valid}
            data-cy="reset-password-submit"
          >
            <span className="font-medium">{t('reset-password.buttons.continue')}</span>
          </Button>
          <div className="mt-4 text-center font-medium">
            <span className="cursor-pointer hover:underline" data-cy="back-link" onClick={() => navigate('/auth/login')}>
              {t('reset-password.buttons.back')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
