import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';

const ResetPasswordExpired: FC = () => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  return (
    <>
      <Heading size={HeadingSize.H1}>{t('reset-password.expired.heading')}</Heading>
      <div>{t('reset-password.expired.subheading')}</div>
      <div className="w-96 text-center">
        <Button className="mt-6 w-full" type={ButtonType.PRIMARY} onClick={() => navigate('/auth/forgot-password')} data-cy="restart-flow">
          <span className="font-medium">{t('reset-password.expired.buttons.restart-flow')}</span>
        </Button>
        <div className="mt-4 text-center font-medium">
          <span className="cursor-pointer hover:underline" data-cy="back-link" onClick={() => navigate('/auth/login')}>
            {t('reset-password.expired.buttons.back')}
          </span>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordExpired;
